import React from "react"
import "./css/project.css"
import Img from "gatsby-image"
import Info from "./info";

class ProjectHeader extends React.Component {
    render(){

        const headerImage = this.props.headerImage

        return (
            <div className="projectHeader">
                <h1>
                    {this.props.title}
                </h1>
                <h2>
                    {this.props.subtitle}
                </h2>                
                <div className="abstract">
                    { this.props.abstract }
                </div>
                <div className="intro-image">                    
                    <img src={headerImage} />
                    <div className="intro-image-image" style={{backgroundImage: "url(" + headerImage + ")"}}></div>
                    <div className="intro-image-description">
                        <p>{this.props.imageDescription}</p>
                    </div>
                </div>
            </div>     
        )
    }
}

export default ProjectHeader