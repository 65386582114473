import React from "react"
import "./css/project.css"

export default ({ info, children }) => (
    <section className="project-body">
        <div className="info">
            { info }
        </div>
        <div className="project">
            { children }
        </div>
    </section>
)