import React from "react"
import "./css/project.css"

class ProjectContent extends React.Component {
    render() {
        return (
            <div className="projectContent">
                <div className="content">
                    { this.props.content }
                </div>                
            </div>     
        )
    }
}

export default ProjectContent