import React from "react"
import { Link, graphql } from "gatsby"

import Project from "../components/project"
import Info from "../components/info"
import ProjectFooter from "../components/projectFooter"
import ProjectHeader from "../components/projectHeader"
import ProjectContent from "../components/projectContent"
import _ from "lodash"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import RichText from '@madebyconnor/rich-text-to-jsx'
import Meta from "../components/meta";

class ProjectPage extends React.Component {
  render() {        
    const data = this.props.data.allContentfulProject
    const project = data.edges[0].node
    const abstract = documentToReactComponents(data.edges[0].node.abstract.json) 
    
    const ImageComponent = ({ src, description, title }) => (      
      <div className="contentImage">
        <img title={title} src={ src } />
        <span className="imageDescription">
          { description }
        </span>      
      </div>
    )
    
    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          let path = node.data.target.fields
          if(path != undefined){
            let url = path.file['en-US'].url
            let description = path.description['en-US']
            let title = path.title['en-US']          
            return <ImageComponent title={title} src={url} description={description}/>
          }           
        }
      }
    }

    const content = documentToReactComponents(data.edges[0].node.content.json, options)

    const info  = <Info author="Philipp Kaltofen" links={project.infoLinks} title={project.title} roles={project.infoRoles} team={project.infoTeam} type={project.info} year={project.infoYear} tags={project.infoTags}></Info>

    return (
      <Project info={info}>
        <Meta></Meta>
        <ProjectHeader imageDescription={project.thumbnail.description} headerImage={data.edges[0].node.thumbnail.fluid.src} abstract={abstract} title={data.edges[0].node.title} subtitle={data.edges[0].node.subtitle}></ProjectHeader>        
        <ProjectContent content={content}></ProjectContent>
        <ProjectFooter title={project.title}></ProjectFooter>
      </Project>
    )
  }
}

export default ProjectPage

export const pageQuery = graphql`
query projectQuery($title: String!){
  allContentfulProject(filter:{title:{eq:$title}}) {
    edges {
      node {
        title
        subtitle
        infoYear
        info
        infoTags      
        infoRoles  
        infoLinks
        infoTeam
        abstract {
          json
        }
        content {
          json
        }
        thumbnail{
          fluid(quality:100){
            src
          }
          description
        }
      }
    }
  }
}

`